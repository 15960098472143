import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const AmoriMobilePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Amori",
    location: "New York, NY",
    industry: "Dating",
    developmentType: "Mobile App",
    scope: "Design and Full Stack Development",
    businessObjectives:
      "To capture a large portion of the US dating market by catering to all sexualities in a single app. Amori will target a financial break even point of 100,000 downloads within the first year as they ramp up usage.",

    desiredOutcomes:
      "Amori needs an easy to use dating application that is visually appealing and fun to use. There needs to be multiple ways to find users, not just by swiping. Users must also have the ability to upload videos onto their profiles. Revenue will be generated with user spotlighting and VIP upgrades. To achieve the business objectives, the app will need to convert 6% of users.",

    targetMarket: "Singles Aged 18-35",
    competition: "Tinder",
    competitiveAdvantage:
      "More ways to find matches for all sexual preferences",
    timeFrame: "6 Months",
  }

  const userStories = {
    mustHaves: [
      "Login screen allows user to log in with either Google or Facebook",
      "Discover screen is a swipe style matching system with a like, dislike and favorite button",
      "Tapping on a user's image brings up their profile with name, location, age, etc.",
      "Nearby screen allows users to find friends within a certain distance using a filter",
      "Spotlight screen to show most popular users with ability to pay for a spot",
      "Find users by interest through a interest tag search",
      "Favorites screen shows all favorited users with ability to sort by matches and likes",
      "Messaging screen that allows you to see all your communications",
      "Opening a message allows you to text chat and send images within the chat window",
      "Rating modal popup allowing users to rate the app",
      "Profile screen that allows users to change settings and become VIP",
      "Profile edit screen to upload photos, add interests, stats, etc.",
      "VIP Center screen to allow users to upgrade and become VIP members",
      "Desktop admin application to manage users",
    ],
    niceToHaves: [
      "Face recognition to match pictures to live person",
      "Date recommendations in message window",
      "Profile recommendations to get more matches",
      "Incognito mode so VIPs can browse anonymously",
      "Blacklisting users and allowing them to only see other blacklisted users",
      "Chat bot integration to provide customer support for VIPs",
      "Ability to add videos in profile",
      "Push notifications for new matches",
    ],
    maybeLater: [
      "Free VIP trial memberships",
      "Inappropriate picture filter",
      "Virtual video group dates",
      "Round robin chat sessions",
      "Add an AI bot to help users with frequently asked questions",
      "Match through questionnaire",
      "Staged matching where users go from basic to questionnaire to round robin, etc",
      "Refer a friend link",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Database selection and setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Database configuration for user login",
        "Login Screen design & development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Discover Screen design & development",
        "Database development for user profile name and image",
        "Create logged in user profile",
        "Enable refresh, dislike, like and favorite button overlay",
      ],
      weeks: "Week 3-4",
    },
    iteration4: {
      stories: [
        "Match Profile Screen design & development",
        "Database development to add additional user profile properties",
        "Linked match profile to discover screen",
        "Enabled like and favorite buttons",
      ],
      weeks: "Week 5-6",
    },
    iteration5: {
      stories: [
        "Created large list of dummy users for testing purposes",
        "Nearby search screen design & development",
        "Spotlight screen design & development",
        "Filter options screen design & development",
      ],
      weeks: "Week 7-8",
    },
    iteration6: {
      stories: [
        "Design and develop order summary (shopping cart) screen",
        "Develop a local shopping cart state and enable 'Add To Cart' buttons on menu and details screens",
        "Connect order summary page and shopping cart state",
        "Develop financial calculations for order summary page",
      ],
      weeks: "Week 9-10",
    },
    iteration7: {
      stories: [
        "Find Someone By Tags screen design & development",
        "Expanded user profile properties in database",
      ],
      weeks: "Week 11",
    },
    iteration8: {
      stories: [
        "Favorites screen design & development",
        "Favorites filter dropdown design & development",
        "Updated database to include favorites",
      ],
      weeks: "Week 12-13",
    },
    iteration9: {
      stories: [
        "Messaging centre design & development",
        "Database development for messaging and Socket.io integration",
        "Online user display",
      ],
      weeks: "Week 14-15",
    },
    iteration10: {
      stories: ["Rating Modal popup design & development"],
      weeks: "Week 16",
    },
    iteration11: {
      stories: [
        "Design and develop Profile Edit screen which includes stats, images and video uploads.",
        "Added database support",
      ],
      weeks: "Week 17-18",
    },
    iteration12: {
      stories: [
        "Design & develop VIP Upgrade screen",
        "Add backend support to enable VIP privileges",
      ],
      weeks: "Week 19",
    },
    iteration13: {
      stories: [
        "Design & develop Settings, Likes, Visits, Wallet, and Blacklist screens",
      ],
      weeks: "Week 20-21",
    },
    iteration14: {
      stories: [
        "Profile screen design and development",
        "Link previously created screens like edit, settings, etc. to Profile screen",
      ],
      weeks: "Week 22",
    },
    iteration15: {
      stories: ["Desktop Admin web app creation for basic user management"],
      weeks: "Week 23-24",
    },
    iteration16: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 25",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "JavaScript",
      "React Native",
      "Redux",
      "Jest",
      "Node",
      "Mongodb",
      "Git",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "The Amori Team had many innovative ideas but not enough time to develop them all.",
      win: (
        <>
          <p>
            Amori's goal is to develop the most innovative dating application
            available on the market. From deterring catfish with facial
            recognition to virtual video group dates through the app, they had a
            massive story list but not enough time and budget to build them all.
          </p>
          <p>
            By working with the Amori team, we carefully selected their "must
            have" stories so they would get an app release that would help them
            achieve their desired business outcomes.
          </p>
          <p className="mb-0">
            Using an agile approach we prioritized, sized and iterated their
            stories so they would up and running within their 6 month time
            frame. This gave them the ability to build their business, generate
            profits and plan for the next app release with extended features.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Amori's video hosting platform was providing poor performance with slow video load times.",
      win: (
        <>
          <p>
            Amori provided Tactic Apps with a video host that wasn't performing
            well enough to deploy at large scale. Since video would be available
            for all user profiles it was necessary to move the video hosting to
            a more reliable source.
          </p>
          <p className="mb-0">
            After evaluating many of the top hosting sites, we settled on using
            Vimeo as the best alternative. This provided stable performance at
            scale.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "The Amori team wanted to go live on both iOS and Android without enough time for development on both platforms.",
      win: (
        <>
          <p>
            It is quite common for our clients to want applications developed
            for both iOS and Android. Amori's tight budget and time line would
            not allow for this.
          </p>
          <p className="mb-0">
            However, to save Amori a significant amount of time and money, the
            Tactic Apps team used React Native to develop their iOS application.
            This allowed us to build and test their Android application within
            one month after release instead of starting from scratch.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "Amori had a change in management which led to a change in priorities for the application.",
      win: (
        <>
          <p>
            Amori brought in new management in the first 2 months of the
            project. This new team had different ideas on user story
            prioritization. Some of the requested changes were the ability to
            add user video and allowing users to blacklist others.
          </p>
          <p className="mb-0">
            Thanks to our commitment to Agile Project Management, we were able
            to show the Amori team where changes could be made and how we needed
            to focus on scope since time and budget were fixed. We were able to
            swap out some user stories and implemented the necessary changes as
            requested.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "Amori's payment gateway wasn't integrating well enough to provide a cohesive user experience.",
      win: (
        <>
          <p>
            After seeing the integration capabilities of Amori's current payment
            processor, we realized a change needed to be made. It was a
            frustrating user experience and broke the cohesion of the
            application.
          </p>
          <p className="mb-0">
            The Tactic Apps team evaluated other online processing methods and
            suggest Stripe due to their powerful API and seamless integration.
            Amori gladly accepted this advice as it also allowed the to easily
            integrate other avenues of revenue like "Spotlight Activation".
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "3",
      hourly: "145",
      time: "6",
    },
  }

  const resultsImageNames = {
    results1: "Landing Screen",
    results2: "Discover",
    results3: "Matched",
    results4: "Match Profile",
    results5: "Match Profile Scrolled",
    results6: "All Users",
    results7: "Rate Us Popup",
    results8: "Spotlight",
    results9: "Filter Users",
    results10: "Find User by Tag",
    results11: "View All Favorites",
    results12: "Filter Favorites By Tag",
    results13: "Messages",
    results14: "Chat",
    results15: "User Profile",
    results16: "User Profile Edit",
    results17: "User Profile Edit Scrolled",
    results18: "Upload Video",
    results19: "VIP Center",
    results20: "VIP Center Scrolled",
  }

  const resultsImageModalSize = "md"

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(relativePath: { eq: "work/mobile/amori/amori-hero.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/mobile/amori/amori-devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/mobile/amori/wireframes/login_discover.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/mobile/amori/wireframes/match_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: { eq: "work/mobile/amori/wireframes/nearby.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: { eq: "work/mobile/amori/wireframes/favorites.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: { eq: "work/mobile/amori/wireframes/messages.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/mobile/amori/wireframes/user_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/mobile/amori/wireframes/edit_user_profile.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: { eq: "work/mobile/amori/1-login.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: { eq: "work/mobile/amori/2-discover.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: { eq: "work/mobile/amori/2-1-matched.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: { eq: "work/mobile/amori/3-match-profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: { eq: "work/mobile/amori/4-match-profile2.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: { eq: "work/mobile/amori/5-all-users.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/mobile/amori/5-1-all-users-rate-us.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: { eq: "work/mobile/amori/6-spotlight.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: { eq: "work/mobile/amori/7-filters.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: { eq: "work/mobile/amori/7-1-find-by-tag.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results11: file(
            relativePath: { eq: "work/mobile/amori/8-all-favorites.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results12: file(
            relativePath: { eq: "work/mobile/amori/9-favorites-dropdown.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results13: file(
            relativePath: { eq: "work/mobile/amori/10-messages.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results14: file(
            relativePath: { eq: "work/mobile/amori/11-chat.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results15: file(
            relativePath: { eq: "work/mobile/amori/12-user-profile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results16: file(
            relativePath: { eq: "work/mobile/amori/13-user-profile-edit.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results17: file(
            relativePath: { eq: "work/mobile/amori/14-user-profile-edit.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results18: file(
            relativePath: {
              eq: "work/mobile/amori/15-user-profile-upload-video.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results19: file(
            relativePath: { eq: "work/mobile/amori/16-VIP-center.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results20: file(
            relativePath: { eq: "work/mobile/amori/17-VIP-center2.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Amori Mobile App Case Study"
            keywords={[`amori mobile app case study`]}
            description="Amori is a US based mobile dating application that required full stack development services. It is visually stunning and appeals to a large variety of dating preferences."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Mobile Application Case study"
            subTitle="Amori"
            pageContext={pageContext}
            location={location}
            crumbLabel="Amori"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageModalSize={resultsImageModalSize}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default AmoriMobilePage
